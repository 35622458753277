/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
const getLongStringShow = (addr = "", before = 6, after = 4) => {
  addr = "" + addr;
  if (!addr || addr.length < before + after) {
    return addr;
  } else {
    return addr.substring(0, before) + "..." + addr.substring(addr.length - after, addr.length);
  }
};

const copyString = (string = "") => {
  const sid = "" + string;
  const el = document.createElement('textarea');
  el.value = sid;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  const getSel:any = document.getSelection();
  const selected = getSel.rangeCount > 0 ? getSel.getRangeAt(0) : false;
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    getSel.removeAllRanges();
    getSel.addRange(selected);
  }
}

export {
	getLongStringShow,
  copyString,
}
