/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
const modifyImgSize = (src = "", size = "_480x480") => {
  return src.indexOf("instaclip06") > -1 ? src + size + "?format=webp" : src;
};

export {
  modifyImgSize,
}
