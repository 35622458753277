import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/webps/checkOn.webp'


const _hoisted_1 = { class: "flex items-center py-1.5 px-3 hover-animation hover:bg-solid rounded-lg" }
const _hoisted_2 = { class: "w-4.5 h-4.5 mr-4 bg-modal rounded" }
const _hoisted_3 = {
  key: 0,
  class: "w-4.5 h-4.5",
  src: _imports_0,
  alt: ""
}
const _hoisted_4 = {
  class: "w-10 h-10 border-4 rounded-1/2 box-content border-n2 mr-3",
  alt: ""
}
const _hoisted_5 = { class: "flex-grow flex-shrink w-1/2" }
const _hoisted_6 = { class: "text-16-22 text-white whitespace-nowrap overflow-ellipsis overflow-hidden" }
const _hoisted_7 = { class: "flex items-center text-14-16 text-customSet-4 mt-1" }
const _hoisted_8 = { class: "mr-3" }
const _hoisted_9 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazyload = _resolveDirective("lazyload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isMultiple ? _ctx.item?.checked : _ctx.selectIndex === _ctx.index)
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    _withDirectives(_createElementVNode("img", _hoisted_4, null, 512), [
      [_directive_lazyload, _ctx.modifyImgSize(_ctx.item?.collectionLogo, '_40x40')]
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.item?.collectionName), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.item?.holdCount) + "NFTs", 1),
        _createElementVNode("div", _hoisted_9, "Value " + _toDisplayString(_ctx.kmbNumber(_ctx.item?.valCount)) + " ETH", 1)
      ])
    ])
  ]))
}