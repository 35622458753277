/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { createVNode, render, nextTick } from 'vue'
import toast from './Toast.vue';
let vm: any = null;
const Toast = (options: any) => {
  if (vm) {
    vm.component.proxy.visible = false;
    clearTimeout(vm.timer);
  }
  const container: any = document.createElement("div");
  const opt: any = {};
  opt.message = typeof options === "string" || !options ? options || "" : options.message;
  opt.duration = options?.duration || 1500;
  opt.position = options?.position || "middle";
  opt.html = options?.html || "";
  opt.canClick = options?.canClick || false;
  opt.autoHide = options?.autoHide === false ? false : true;
  opt.imgSrc = options?.imgSrc || "";
  opt.imgPosition = options?.imgPosition || "top";
  opt.width = options?.width || "";
  opt.height = options?.height || "";
  opt.topHeight = options?.topHeight || "";
  opt.bottomHeight = options?.bottomHeight || "";
  vm = createVNode(toast, opt);
  render(vm, container);
  document.body.appendChild(container);
  nextTick(() => {
    vm.component.proxy.visible = true;
    if (opt.autoHide) {
      vm.timer = setTimeout(() => {
        vm.component.proxy.visible = false;
      }, opt.duration);
    }
  });
}
Toast.closed = () => {
  vm.component.proxy.visible = false;
};
export default Toast