
import { defineComponent, ref } from "vue";

export default defineComponent({
  components: {},
  props: {
    message: {
      type: String,
      default: "",
    },
    html: {
      type: String,
      default: "",
    },
    canClick: {
      type: Boolean,
      default: false,
    },
    autoHide: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 1500,
    },
    imgSrc: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      default: "middle",
    },
    imgPosition: {
      type: String,
      default: "top",
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    topHeight: {
      type: String,
      default: "",
    },
    bottomHeight: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const visible = ref(false);

    return {
      visible,
    };
  },
});
