import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center py-1.5 px-3 rounded-lg bg-primary-16 hover-animation hover:bg-primary-12 text-primary-100 hover-animation hover:text-primary-50" }
const _hoisted_2 = {
  class: "w-5 h-5 rounded-1/2 mr-2",
  alt: ""
}
const _hoisted_3 = { class: "font-bold text-12-14 mr-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazyload = _resolveDirective("lazyload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
      [_directive_lazyload, _ctx.modifyImgSize(_ctx.item?.collectionLogo, '_40x40')]
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item?.collectionName), 1),
    _createElementVNode("i", {
      class: "font-normal icon-close ml-1.5 flex-grow-0 flex-shrink-0",
      style: {"font-size":"12px","line-height":"12px"},
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('deleteItem')))
    })
  ]))
}