
import { defineComponent } from "vue";
import { getLongStringShow } from "@/utils/formatString";
import { modifyImgSize } from "@/utils/imgSize";
export default defineComponent({
  name: "FilterCollection",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {
      modifyImgSize,
      getLongStringShow,
    };
  },
});
