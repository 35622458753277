const newsDomain = process.env.VUE_APP_API_DOMAIN;
const isTest = process.env.VUE_APP_MODE === "production" ? "" : "?env=test"
// GET
const accountWallet = (walletAddr: string) => `${newsDomain}onft/v3/api/account/${walletAddr}${isTest}`;
// GET
const accountWalletContracts = (walletAddr: string) => `${newsDomain}onft/v3/api/account/${walletAddr}/contracts${isTest}`;
// GET
const accountContracts = (walletAddr: string, contractAddr: string) => `${newsDomain}onft/v3/api/account/${walletAddr}/contracts/${contractAddr}/nfts${isTest}`;
// GET
const accountHistory = (walletAddr: string) => `${newsDomain}onft/v3/api/account/${walletAddr}/history${isTest}`;

// GET
const accountNfts = (walletAddr: string) => `${newsDomain}onft/v3/api/account/${walletAddr}/nfts${isTest}`;

// GET
const collectionDetail = `${newsDomain}onft/v3/api/collection${isTest}`;
export {
  accountWallet,
  accountWalletContracts,
  accountContracts,
  accountHistory,
  accountNfts,
  collectionDetail,
}