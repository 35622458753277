/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { get } from "@/apis/http";
import {
  accountWallet,
  accountWalletContracts,
  accountContracts,
  accountHistory,
  accountNfts,
  collectionDetail,
} from "@/apis/api";
import { successCallback, errorCallback } from "@/apis/constants";

const getAccountWalletContracts = (params?: any) => {
  return get(accountWalletContracts(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountWallet = (params?: any) => {
  return get(accountWallet(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountContracts = (params?: any) => {
  return get(accountContracts(params?.walletAddr, params?.contractAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountHistory = (params?: any) => {
  return get(accountHistory(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getAccountNfts = (params?: any) => {
  return get(accountNfts(params?.walletAddr), { ...params }).then(successCallback).catch(errorCallback)
};

const getCollectionDetail = (params?: any) => {
  return get(collectionDetail, { ...params }).then(successCallback).catch(errorCallback)
};

export {
  getAccountWallet,
  getAccountWalletContracts,
  getAccountContracts,
  getAccountHistory,
  getAccountNfts,
  getCollectionDetail,
}
