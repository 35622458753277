import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text-center text-base text-white whitespace-pre-line" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["w-full h-full fixed overflow-hidden left-0 top-0 box-border flex justify-center items-center flex-col z-50", {
      'justify-start': _ctx.topHeight,
      'justify-end': _ctx.bottomHeight,
    }]),
        style: _normalizeStyle({
      'padding-top': _ctx.topHeight ? _ctx.topHeight : '0',
      'padding-bottom': _ctx.bottomHeight ? _ctx.bottomHeight : '0',
    })
      }, [
        (_ctx.html)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "rounded max-w-2/3 max-h-1/2 p-2 bg-n1 text-white flex justify-center items-center",
              innerHTML: _ctx.html
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["rounded max-w-2/3 max-h-1/2 p-2 bg-n1 text-white flex justify-center items-center", {
        'p-6': _ctx.imgSrc,
        'flex-col': _ctx.imgPosition === 'top',
        'flex-col-reverse': _ctx.imgPosition === 'bottom',
        'flex-row': _ctx.imgPosition === 'left',
        'flex-row-reverse': _ctx.imgPosition === 'right',
      }]),
              style: _normalizeStyle({
        width: _ctx.width ? _ctx.width : 'auto',
        height: _ctx.height ? _ctx.height : 'auto',
      })
            }, [
              (_ctx.imgSrc)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: _normalizeClass([{
          'mb-2': _ctx.imgPosition === 'top',
          'mt-2': _ctx.imgPosition === 'bottom',
          'mr-2': _ctx.imgPosition === 'left',
          'ml-2': _ctx.imgPosition === 'right',
        }, "w-6 h-6 text-white"]),
                    src: _ctx.imgSrc
                  }, null, 10, _hoisted_2))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.message), 1)
            ], 6))
      ], 6))
    : _createCommentVNode("", true)
}