import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "itemDom",
  class: "itemDom"
}
const _hoisted_2 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NftSimple = _resolveComponent("NftSimple")!
  const _component_Collection = _resolveComponent("Collection")!
  const _component_FilterCollection = _resolveComponent("FilterCollection")!
  const _component_ProfileNft = _resolveComponent("ProfileNft")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'lsim')
      ? (_openBlock(), _createBlock(_component_NftSimple, {
          key: 0,
          item: _ctx.item,
          index: _ctx.index
        }, null, 8, ["item", "index"]))
      : (_ctx.type === 'Collection')
        ? (_openBlock(), _createBlock(_component_Collection, {
            key: 1,
            item: _ctx.item,
            index: _ctx.index
          }, null, 8, ["item", "index"]))
        : (_ctx.type === 'FilterCollection')
          ? (_openBlock(), _createBlock(_component_FilterCollection, {
              key: 2,
              item: _ctx.item,
              index: _ctx.index
            }, null, 8, ["item", "index"]))
          : (_ctx.type === 'ProfileNft')
            ? (_openBlock(), _createBlock(_component_ProfileNft, {
                key: 3,
                item: _ctx.item,
                index: _ctx.index
              }, null, 8, ["item", "index"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2))
  ], 512))
}