
import { defineComponent } from "vue";
import { getLongStringShow } from "@/utils/formatString";
import { modifyImgSize } from "@/utils/imgSize";
import { kmbNumber, getFloat } from "@/utils/formatNumber";
export default defineComponent({
  name: "Collection",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    selectIndex: {
      type: Number,
      default: -1,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      modifyImgSize,
      getLongStringShow,
      kmbNumber,
    };
  },
});
