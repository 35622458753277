
import { defineComponent, reactive, computed, ref } from "vue";
import { getQueryString } from "@/utils/url";
import { formatPrice, getFloat, kmbNumber } from "@/utils/formatNumber";
import { decimalizeNumber } from "@/utils/numberUtils";
import { getLongStringShow } from "@/utils/formatString";
import { modifyImgSize } from "@/utils/imgSize";
import { compressToBase64 } from "lz-string";

export default defineComponent({
  name: "ProfileNft",
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const loading = ref();
    return {
      loading,
      getQueryString,
      formatPrice,
      getLongStringShow,
      getFloat,
      kmbNumber,
      decimalizeNumber,
      modifyImgSize,
      compressToBase64,
    };
  },
});
