/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

interface UrlQuery {
  [key: string]: string
}

/**
 * getDomainFromUrl
 * @param {string} url
 * @description Get domain from url
 */
const getDomainFromUrl = (url: string) => {
  try {
    return new URL(url).hostname
  } catch (e) {
    return ''
  }
}
const getQueryObj = (queryString = "") => {
  const obj:any = {}
  if (queryString) {
    const queryArray = queryString.split("&");
    queryArray.forEach(item => {
      if (item) {
        const arr = item.split("=");
        if (arr[0]) {
          obj[arr[0]] = arr?.[1] || "";
        }
      }
    });
  }
  return obj
}
const getQueryString = (ignore: string[] = []) => {
  const queryObj = getQueryObj(location.href.split("?")?.[1] || localStorage.getItem("queryString") || "");
  ignore.forEach(it => {
    delete queryObj?.[it];
  });
  const queryArray: string[] = [];
  Object.keys(queryObj).forEach(item => {
    queryArray.push(`${item}=${queryObj?.[item] || ""}`);
  });
  const queryString = queryArray.join("&");
  return queryString;
};

/**
 * getQueriesFromUrl
 * @param {string} url
 * @description Get queries from url
 */
const getQueriesFromUrl = (url: string): UrlQuery => {
  const obj:any = {}
  const queryString = url.split("?")?.[1] || "";
  if (queryString) {
    localStorage.setItem("queryString", queryString);
    const queryArray = queryString.split("&");
    queryArray.forEach(item => {
      const arr = item.split("=");
      obj[arr[0]] = arr?.[1] || "";
    });
  }
  return obj
};

const historyReplaceState = (routeString = "", paramsValue = "") => {
  const hrefArray = location.href.split("?");
  const path = hrefArray[0].split("://");
  const hostnames = path[1];
  const hostnameArray = hostnames.split(routeString);
  let route = routeString;
  if (route[route.length - 1] !== "/") route += "/";
  window.history.replaceState(
    null,
    "",
    `${path[0]}://${hostnameArray[0]}${route}${paramsValue}?${hrefArray?.[1] || ""}`
  );
};

const getQueryStringFromObj = (obj:any) => {
  const queryArray: string[] = [];
  Object.keys(obj).forEach(item => {
    queryArray.push(`${item}=${obj?.[item] || ""}`);
  });
  const queryString = queryArray.join("&");
  return queryString;
};

const historyReplaceStateQuery = (query:any) => {
  const hrefArray = location.href.split("?");
  const queryString = hrefArray?.[1]||"";
  let queryObj = getQueryObj(queryString);
  queryObj = {...queryObj, ...query};
  window.history.replaceState(
    null,
    "",
    `${hrefArray[0]}?${getQueryStringFromObj(queryObj) || ""}`
  );
};

export {
  getDomainFromUrl,
  getQueriesFromUrl,
  getQueryObj,
  getQueryString,
  historyReplaceState,
  historyReplaceStateQuery,
}
