
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";

import NftSimple from "@/components/list/items/NftSimple.vue";
import Collection from "@/components/list/items/Collection.vue";
import FilterCollection from "@/components/list/items/FilterCollection.vue";
import ProfileNft from "@/components/list/items/ProfileNft.vue";

export default defineComponent({
  name: "ListItems",
  components: {
    NftSimple,
    Collection,
    FilterCollection,
    ProfileNft,
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    return {};
  },
});
