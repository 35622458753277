
export enum StatusCode {
  SUCCESS = 200
}



export const successCallback = ({ status={}, data={} }) => {
  if (status === StatusCode.SUCCESS) {
    return data
  }
  return undefined
}

export const errorCallback = (error:any) => {
  const { data = {} } = error;
  console.error("http-err:", data);
  return data
}
