import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4cf986e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "w-6 h-6 m-2 animate-rotate",
      src: _ctx.loading,
      alt: "loading"
    }, null, 8, _hoisted_2)
  ]))
}