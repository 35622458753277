/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

const getFloat = (num: number, n = 4, toFixed = true, isFloor = true) => {
  let res: string;
  const symbol = num >= 0 ? "" : "-"
  num = Math.abs(num);
  num = isFloor ? Math.floor(num * Math.pow(10, n)) / Math.pow(10, n) : Math.ceil(num * Math.pow(10, n)) / Math.pow(10, n);
  res = Number(num).toFixed(n);
  if (!toFixed) {
    while (res.indexOf(".") > -1 && res[res.length - 1] == "0") {
      res = res.substring(0, res.length - 1);
    }
    if (res[res.length - 1] == ".") {
      res = res.substring(0, res.length - 1);
    }
  }
  return symbol + res;
};


/**
 * 12345.789 -> 12,345.789
 * @param {}
 * @returns
 */
const formatNumber = (v = 0, afterLength = 4, leng = 3) => {
  const n = Number(v);
  if (!n) { return 0; }
  let counter = 0;
  const newn = Math.floor(n * Math.floor(Math.pow(10, afterLength))) / Math.floor(Math.pow(10, afterLength));
  const int = newn.toString().split('.');
  const num = int[0].split('');
  const result = int[1] ? [`.${int[1]}`] : [];
  for (let i = num.length - 1; i >= 0; i--) {
    counter++;
    result.unshift(num[i]);
    if (!(counter % leng) && i !== 0) {
      result.unshift(',');
    }
  }
  return result.join('');
};


function formatSmallNumber(v = 0, leng = 2) {
  const n = Number(v);
  if (!n) { return 0; }
  let resultStr = "";
  if (n >= 1) {
    resultStr = getFloat(n, leng, false);
  } else {
    const nstr = '' + n; // 小数转字符串
    let oNum = 0; // 小数点后0的个数
    let min1 = ""; // 小于0.1-1之间的小数
    if (nstr.indexOf("e-") > -1) {
      const strArr = nstr.split('e-');
      oNum = Number(strArr[1]) - 1;
      min1 = Number(strArr[0]) / 10 + '';
    } else {
      const nArr = nstr.split('.');
      const str = nArr[1] || "";
      for (let i = 0; i < str.length; i++) {
        if (str[i] !== "0") {
          oNum = i;
          break;
        }
      }
      min1 = "0." + str.substr(oNum, str.length - oNum);
    }
    const knum = Math.floor(Number(min1) * Math.pow(10, leng));
    let res = "0.";
    for (let i = 0; i < oNum; i++) {
      res += "0";
    }
    resultStr = res + knum;
    while (resultStr[resultStr.length - 1] == "0") {
      resultStr = resultStr.substring(0, resultStr.length - 1);
    }
  }
  return resultStr;
}
const formatPrice = (v = 0, toFixed = true) => {
  let n = Number(v);
  if (!n) { return 0; }
  let resultStr = "";
  let abs = false;
  if (n < 0) {
    n = Math.abs(n);
    abs = true;
  }
  if (n > 1000000000) {
    const nu = getFloat(n / 1000000000, 2, toFixed);
    resultStr = nu + "B";
  } else if (n >= 10) {
    resultStr = getFloat(n, 2, true);
  } else if (n >= 1) {
    resultStr = getFloat(n, 5, false);
    const strA = ('' + resultStr).split(".");
    if (strA.length === 1 || strA[1].length < 2) {
      resultStr = getFloat(n, 2, true);
    }
  } else if (n >= 0.00001) {
    resultStr = getFloat(n, 5, false);
    const strA = ('' + resultStr).split(".");
    if (strA.length === 1 || strA[1].length < 2) {
      resultStr = getFloat(n, 2, true);
    }
  } else if (n > 0.000000001) {
    resultStr = getFloat(n, 9, true);
    while (resultStr[resultStr.length - 1] == "0") {
      resultStr = resultStr.substring(0, resultStr.length - 1);
    }
  } else {
    if (abs) {
      resultStr = "< -0.000000001";
    } else {
      resultStr = "<0.000000001";
    }
  }
  return resultStr;
};

const getNumberShow = (num: number, length = 2) => {
  let n = "" + num;
  while (n.length < length) {
    n = "0" + n;
  }
  return n;
};

const kmbNumber = (n = 0, smallNumber = 4) => {
  if (!n) {
    return "--";
  }
  if (n >= 1000000000) {
    return getFloat(n / 1000000000, 2, false) + "B";
  } else if (n >= 1000000) {
    return getFloat(n / 1000000, 2, false) + "M";
  } else if (n >= 1000) {
    return getFloat(n / 1000, 2, false) + "K";
  } else if (n >= 1) {
    return getFloat(n, 2, false);
  } else {
    return getFloat(n, smallNumber, false, false);
  }
};

const formatPercentageValue = (val = 0) => {
  val *= 100;
  const symbol = val > 0 ? "+" : "";
  if (Math.abs(val) < 0.01) {
    return "--";
  }
  if (Math.abs(val) > 1000) {
    return symbol + val.toFixed(0) + "%";
  } else {
    return symbol + getFloat(val, 2, false) + "%";
  }
};

export {
  getFloat,
  formatNumber,
  formatSmallNumber,
  formatPrice,
  getNumberShow,
  kmbNumber,
  formatPercentageValue,
}
